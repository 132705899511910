import React, { useEffect } from "react"

import Link from "next/link"
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap"
import classNames from "classnames"
import useOnclickOutside from "react-cool-onclickoutside"
import { withI18n } from "~/hocs"
import CategoryService from "~/services/CategoryService"
import router, { useRouter } from "next/router"
import { routes } from "~/router"
import { buildQuery } from "~/utils"
import { createStructuredSelector } from "reselect"
import { makeSelectIsAuthenticated } from "~/store/auth/selector"
import { connect } from "react-redux"
import { useSession } from "next-auth/react"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import arrayMove from "array-move"
import { Form } from "react-bootstrap"

/**
 * Definition of a SearchPanelChoice
 */
export interface SearchPanelChoice {
  /**
   * Key for a choice can be any unique string.
   * For link variant, recommend using a url.
   */
  key: string

  /**
   * The description that will be displayed in search results.
   */
  description: string
}

/**
 * Definition of props for SearchPanel.
 */
interface SearchPanelProps {
  /**
   * An array of choices to be displayed.
   */
  choices: Array<SearchPanelChoice>

  /**
   * Optional class name to be applied to the top level of the component.
   */
  className?: string

  /**
   * A label for clear button, should be provided if onClear is provided.
   */
  clearLabel?: string

  /**
   * Indicate when consumer is loading to display a spinner.
   */
  isLoading?: boolean

  /**
   * Optional maximum height of result list in pixels: <SearchPanel maximumHeight={250} />
   */
  maximumHeight?: number

  /**
   * Provide a "None" choice item so user can "unselect" a select choice
   * when variant={SearchPanelVariant.checkbox} or variant={SearchPanelVariant.radio}
   */
  noChoiceItem?: SearchPanelChoice

  /**
   * Function that will handle event when search input changes.
   */
  onChange?: (event: React.ChangeEvent) => void

  /**
   * Function that will handle event when user presses clear button.
   */
  onClear?: (event: React.MouseEvent) => void

  /**
   * Function that will handle event when user presses clear button.
   */
  onSubmit: (
    choice: SearchPanelChoice,
    event?: React.MouseEvent<HTMLAnchorElement> | React.FormEvent,
  ) => void

  /**
   * Function that will handle event when selected items change.
   */
  onSelectionChange?: (selectedChoices: Array<SearchPanelChoice>) => void

  onExpanned?: (isExpanded: boolean) => void

  /**
   * State of selected choices.
   */
  preSelectedChoices?: Array<SearchPanelChoice>

  /**
   * Display a shadow on hover and when expanded.
   */
  shadow?: boolean

  /**
   * A smaller variant.
   */
  small?: boolean

  /**
   * Placeholder label in the search input box.
   */
  placeholder: string

  /**
   * Value of search input.
   */
  value: string

  lng: string

  children?: any

  showSearchButton?: boolean

  size?: string

  rounded?: boolean

  renderChoiceItem?: (choice: SearchPanelChoice) => React.ReactNode | string

  debounce?: number

  isAuthen?: boolean

  isCreator?: boolean
}

/**
 * SearchPanel component
 * @param props
 */
const SearchPanel = (props: SearchPanelProps) => {
  const {
    t,
    lng,
    clearLabel,
    isLoading,
    noChoiceItem,
    onClear,
    onChange,
    onSubmit,
    onExpanned,
    placeholder,
    small,
    value,
    choices,
    children,
    showSearchButton = true,
    size,
    className,
    rounded,
    renderChoiceItem,
    debounce,
    isAuthen,
    exclude_ai,
    creator_name,
    mime_type,
    category,
    category_exclude,
    query_exclude,
    keySearch,
    page,
    page_size,
    sort_by,
  } = props
  
  const { asPath } = useRouter()
  const { data: nextAuthSession } = useSession()
  const isCreator = !!nextAuthSession?.user.creatorId
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState(false)
  const [delaySearch, setDelaySearch] = React.useState<any>()
  const resultContainerId: string = "ResultContainer"
  const searchField = React.useRef<HTMLInputElement>(null)
  const categoryRef = React.useRef<HTMLInputElement>(null)
  const categoryRefMobile = React.useRef<HTMLInputElement>(null)
  const categoryExcludeRef = React.useRef<HTMLInputElement>(null)
  const categoryExcludeRefMobile = React.useRef<HTMLInputElement>(null)
  const [categories, setCategories] = React.useState([])
  const [dataSearch, setDataSearch] = React.useState({
    search: keySearch,
    query_exclude: query_exclude ? query_exclude : "",
    category: category ? category.split(",") : [],
    category_exclude: category_exclude ? category_exclude.split(",") : [],
    mime_type: mime_type ? mime_type : "",
    exclude_ai: exclude_ai ? exclude_ai : true,
    creator_name: creator_name ? creator_name.split(",") : []
  })
  const [currentPageSize, setCurrentPageSize] = React.useState(page_size);
  const [isOpenMoreSearch, setIsOpenMoreSearch] = React.useState(false)
  const [isOpenCategory, setIsOpenCategory] = React.useState(false)
  const [isOpenCategoryMobile, setIsOpenCategoryMobile] = React.useState(false)
  const [isOpenCategoryExclude, setIsOpenCategoryExclude] = React.useState(
    false,
  )
  const [
    isOpenCategoryExcludeMobile,
    setIsOpenCategoryExcludeMobile,
  ] = React.useState(false)
  const [isShowPlaceHolder, setIsShowPlaceHolder] = React.useState(true)
  const [isShowPlaceHolderMobile, setIsShowPlaceHolderMobile] = React.useState(
    true,
  )
  const searchRef = React.useRef(null)
  const inputTagRef = React.useRef(null)
  const inputTagRef1 = React.useRef(null)
  const buttonDelTagRef = React.useRef(null)
  const buttonHideMobileSearchRef = React.useRef(null)
  const prevDataSearchRef = React.useRef(dataSearch)
  const queryExcludeSearchMobileRef = React.useRef(null)
  const sortRef = React.useRef(null)
  const listItemSearchRef = React.useRef(null)
  const [enterCount, setEnterCount] = React.useState(0)
  const sizes = [24, 60, 120]

  if (onClear && !clearLabel) {
    console.log("Developer should provide a value for clearLabel.")
  }

  const getCategories = async () => {
    try {
      const res = await CategoryService.get({ lng: "ja" })
      setCategories(res.data)
    } catch (error) {
      alert("エラー")
    }
  }

  useEffect(() => {
    getCategories()
  }, [])

  // useEffect(() => {
  //   // setIsExpanded(false)
  //   searchField.current.value = value
  // }, [value])

  useEffect(() => {
    onExpanned?.(isExpanded)
  }, [isExpanded])

  /**
   * Handle event when user presses outside this component.
   */
  const handlePressOutside = () => {
    if (searchField.current) {
      searchField.current.blur()
    }
    setIsExpanded(false)
    setIsFocused(false)
  }

  /**
   * Handle event when component receives focus.
   */
  const handleOnFocus = () => {
    setIsFocused(true)
    if (choices.length) {
      setIsExpanded(true)
    }
  }
  /**
   * Handle component blur event to find if the newly focused element
   * is within the component or not. If not, collapse the search bar.
   * @param event
   */
  // const handleOnBlur = (event: React.FormEvent) => {
  const handleOnBlur = () => {
    // const currentTarget = event.currentTarget;
    // // Check the newly focused element in the next tick of the event loop
    // setTimeout(() => {
    //   // Check if the new activeElement is a child of the original container
    //   if (!currentTarget.contains(document.activeElement)) {
    //     // You can invoke a callback or add custom logic here
    //     setIsExpanded(false);
    //   }
    // }, 0);
  }

  /**
   * Handle changing search input
   * @param event
   */
  const handleSearchChange = async (event: React.ChangeEvent) => {
    if (delaySearch) clearTimeout(delaySearch)
    setDelaySearch(
      setTimeout(() => {
        if (onChange) onChange(event)
      }, debounce || 0),
    )
  }

  /**
   * Reset expanded state when choices change
   */
  useEffect(() => {
    if (isFocused) {
      if (choices.length) {
        setIsExpanded(true)
      } else {
        setIsExpanded(false)
      }
    }
  }, [choices])

  /**
   * Definition of ChoiceItem properties
   */
  interface ChoiceItemProps {
    choice: SearchPanelChoice
  }

  /**
   * Definition of ChoiceItem, radio or checkbox input.
   * @param param0
   */
  const ChoiceItem = ({ choice }: ChoiceItemProps) => {
    return (
      <li
        className={"resultListItem"}
        role="presentation"
        onClick={(event) => {
          setIsExpanded(false)
          onSubmit(choice, event)
        }}
      >
        {renderChoiceItem ? (
          renderChoiceItem(choice)
        ) : (
          <span className="resultItem">{choice.description}</span>
        )}
      </li>
    )

    // return (
    //   <div className={'resultItem'}>
    //     <Link href={`/video/discovery?search=${choice.key}`} locale={lng}>
    //       <a className={'linkChoice'}
    //         onClick={(event) => onSubmit(choice, event)}
    //         dangerouslySetInnerHTML={{ __html: choice.description }}
    //       />
    //     </Link>
    //   </div>
    // )
  }

  const handleSubmit = (event?: any) => {
    if (searchField?.current.value.trim().length > 0) {
      onSubmit(
        {
          key: searchField?.current.value,
          description: value,
          category: dataSearch.category,
          category_exclude: dataSearch.category_exclude,
          query_exclude: dataSearch.query_exclude,
          mime_type: dataSearch.mime_type,
          creator_name: dataSearch.creator_name,
          exclude_ai: dataSearch.exclude_ai,
        },
        event,
      )
    }
    handlePressOutside()
  }

  const handleSearchKeyUp = (event: React.KeyboardEvent) => {
    // if (event.key === 'Enter') {
    //   handleSubmit(event)
    // } else
    if (event.key === "Escape") {
      handlePressOutside()
    }
  }

  const clickOutsideRef = useOnclickOutside(handlePressOutside)
  // useKeypress('Escape', handlePressOutside)
  // useKeypress('Enter', handleSubmit)

  const renderListChoices = React.useMemo(() => {
    // if (!choices.length) return <div />
    return (
      <div className={"resultListContainer"}>
        <ul className={"resultList"} role="listbox">
          {/* {noChoiceItem && (
            <ChoiceItem key={noChoiceItem.key} choice={noChoiceItem} />
          )} */}
          {choices.map((choice, idx) => (
            <ChoiceItem key={choice.key} choice={choice} />
          ))}
        </ul>
      </div>
    )
  }, [choices])

  const handleCheckboxChange = (field, value, isChecked) => {
    const dataSearchClone = [...dataSearch[field]]

    if (isChecked) {
      dataSearchClone.push(value)
    } else {
      const index = dataSearchClone.indexOf(value)
      if (index !== -1) {
        dataSearchClone.splice(index, 1)
      }
    }

    setDataSearch({
      ...dataSearch,
      [field]: dataSearchClone,
    })
  }

  const handleSubmitSearch = () => {
    const queryParams = {}
    if (
      !dataSearch.search &&
      dataSearch.category.length == 0 &&
      dataSearch.creator_name.length == 0
    ) {
      alert(
        "下記のいずれかを入力、選択してください。\n・クリエイター名\n・キーワード\n・カテゴリ",
      )
      return false
    }

    if (dataSearch.search) {
      queryParams.search = dataSearch.search
    }

    if (dataSearch.query_exclude) {
      queryParams.query_exclude = dataSearch.query_exclude
    }

    if (dataSearch.category.length > 0) {
      queryParams.category = dataSearch.category
    }

    if (dataSearch.category_exclude.length > 0) {
      queryParams.category_exclude = dataSearch.category_exclude
    }

    if (dataSearch.mime_type) {
      queryParams.mime_type = dataSearch.mime_type
    }

    queryParams.exclude_ai = dataSearch.exclude_ai

    if (dataSearch.creator_name.length > 0) {
      queryParams.creator_name = dataSearch.creator_name
    }

    const queryString = buildQuery(queryParams)
    const url = `${routes.discovery.href}${
      queryString ? `?${queryString}` : ""
    }`

    router.push(url, undefined, {
      shallow: false,
      locale: lng,
    })
    setIsOpenMoreSearch(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsOpenMoreSearch(false)
      }
      if (inputTagRef.current && !inputTagRef.current.contains(event.target)) {
        setIsShowPlaceHolder(true)
      }
      if (
        inputTagRef1.current &&
        !inputTagRef1.current.contains(event.target)
      ) {
        setIsShowPlaceHolderMobile(true)
      }
      if (categoryRef.current && !categoryRef.current.contains(event.target)) {
        setIsOpenCategory(false)
      }
      if (
        categoryExcludeRef.current &&
        !categoryExcludeRef.current.contains(event.target)
      ) {
        setIsOpenCategoryExclude(false)
      }
      if (
        categoryRefMobile.current &&
        !categoryRefMobile.current.contains(event.target)
      ) {
        setIsOpenCategoryMobile(false)
      }
      if (
        categoryExcludeRefMobile.current &&
        !categoryExcludeRefMobile.current.contains(event.target)
      ) {
        setIsOpenCategoryExcludeMobile(false)
      }
    }

    // Gắn sự kiện click vào document
    document.addEventListener("click", handleClickOutside)

    // Xóa sự kiện click khi component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus()
    }
    if (inputTagRef1.current) {
      inputTagRef1.current.focus()
    }
  }, [dataSearch.creator_name])

  useEffect(() => {
    if (!isShowPlaceHolder) {
      inputTagRef.current.focus()
    }
    if (!isShowPlaceHolderMobile) {
      inputTagRef1.current.focus()
    }
  }, [isShowPlaceHolder, isShowPlaceHolderMobile])

  const SortableListItem = SortableElement(({ item, onDelete }) => {
    return (
      <li className="list-group-item" style={{ zIndex: "9999" }}>
        <span>{item}</span>
        <button className="tag-delete" ref={buttonDelTagRef} onClick={onDelete}>
          X
        </button>
      </li>
    )
  })

  const SortableList = SortableContainer(({ items, type }) => {
    return (
      <>
        {isShowPlaceHolder && type == 0 && dataSearch.creator_name.length == 0 && (
          <span
            style={{
              position: "absolute",
              left: "20px",
              top: "8px",
              color: "#aaa",
            }}
          >
            クリエイター名を入力
          </span>
        )}
        {isShowPlaceHolderMobile && type == 1 && dataSearch.creator_name.length == 0 && (
          <span
            style={{
              position: "absolute",
              left: "20px",
              top: "8px",
              color: "#aaa",
            }}
          >
            クリエイター名を入力
          </span>
        )}
        <ul className="list-group">
          {items.map((item, index) => {
            return (
              <SortableListItem
                axis="xy"
                key={index}
                index={index}
                item={item}
                onDelete={() => handleDeleteTag(index)}
              />
            )
          })}
          <input
            type="text"
            ref={type == "1" ? inputTagRef1 : inputTagRef}
            className="input-tag"
            onKeyDown={(e) => {
              const keyCode = e.which || e.keyCode
              const isJapanese = e.keyCode >= 0x3000 && e.keyCode <= 0x9fff
              if (isJapanese) {
                if (keyCode === 13) {
                  e.preventDefault()
                  if (enterCount === 1) {
                    enterCount++
                  } else if (enterCount === 2) {
                    handleAddTag(e.target.value)
                    enterCount = 0
                  }
                }
              } else {
                if (keyCode === 13) {
                  e.preventDefault()
                  handleAddTag(e.target.value)
                }
              }
            }}
          />
        </ul>
      </>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newTags = arrayMove(dataSearch.creator_name, oldIndex, newIndex)
    setDataSearch({
      ...dataSearch,
      creator_name: newTags,
    })
  }
  const handleAddTag = (tagString: string) => {
    if (tagString.trim() === "") {
      handleSubmitSearch()
    }
    const tagArray = tagString.split(/[,、\s]+/).map((tag) => {
      return tag.trim().substring(0, 15)
    })

    const uniqueArr = Array.from(new Set(tagArray))

    const newTags = uniqueArr.filter((tag) => tag !== "").map((tag) => tag)

    const filteredTags = newTags.filter(
      (newTag) => !dataSearch.creator_name.some((tag) => tag === newTag),
    )

    const updatedTags = [...dataSearch.creator_name, ...filteredTags]
    setEnterCount(++enterCount)
    setDataSearch({
      ...dataSearch,
      creator_name: updatedTags,
    })
  }

  const handleDeleteTag = (index: number) => {
    const newTags = [...dataSearch.creator_name]
    newTags.splice(index, 1)
    setDataSearch({
      ...dataSearch,
      creator_name: updatedTags,
    })
  }

  return (
    <div className={className}>
      {/* Mobile Multiple Search */}

      {/* End Mobile Multiple Search */}
      <div className="d-flex">
        <form
          className={classNames("topContainer", {
            [`topContainer-${size}`]: !!size,
          })}
          ref={clickOutsideRef}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onSubmit={handleSubmit}
        >
          <div
            className={classNames("searchContainer", {
              searchContainerExpanded: isExpanded,
              searchContainerExpandedShadow: isExpanded,
              rounded,
            })}
          >
            {showSearchButton && (
              <div className={"clearContainer"}>
                <button
                  className="clearButton"
                  type="button"
                  onClick={() => {
                    handleSubmitSearch()
                  }}
                >
                  <i className="fas fa-search" />
                </button>
              </div>
            )}
            <div className={"flexContainer"}>
              <div className={"inputContainer"}>
                <input
                  ref={searchField}
                  className={`${"inputField"} ${small ? "small" : ""}`}
                  defaultValue={value}
                  type="text"
                  aria-autocomplete="both"
                  aria-haspopup="false"
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  role="combobox"
                  spellCheck="false"
                  title={placeholder}
                  aria-label={placeholder}
                  aria-controls={resultContainerId}
                  aria-expanded={isExpanded}
                  placeholder={placeholder}
                  // onChange={handleSearchChange}
                  onChange={(e) => {
                    setDataSearch({
                      ...dataSearch,
                      search: e.target.value,
                    })
                  }}
                  onKeyUp={handleSearchKeyUp}
                />
              </div>
              {isLoading && (
                <div className="flex-center mx-2">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                  />
                </div>
              )}
              <div
                ref={searchRef}
                className={`clearContainer searchButtonWrapper ${
                  asPath == "/" ? "" : "search-button-show"
                }`}
              >
                <button
                  className="moreSearchButton"
                  type="button"
                  onClick={() => {
                    setIsOpenMoreSearch(!isOpenMoreSearch)
                  }}
                >
                  詳細検索 ▼
                </button>
                {isOpenMoreSearch && (
                  <div
                    className={
                      asPath == "/"
                        ? "advsearcharea advsearcharea-lg-screen border-0 p-0 shadow-none text-dark"
                        : isAuthen
                        ? isCreator
                          ? "advsearcharea advsearcharea-lg-screen w-50 border-0 p-0 shadow-none text-dark"
                          : "advsearcharea advsearcharea-lg-screen w-70 border-0 p-0 shadow-none text-dark"
                        : "advsearcharea advsearcharea-lg-screen w-100 border-0 p-0 shadow-none text-dark"
                    }
                  >
                    <div className="border p-3 shadow-lg">
                      <div className="row d-flex align-items-center ac-mb-2 no-gutters">
                        <div className="col-sm-5 mb-1 mb-sm-0">
                          <span
                            className="float-left"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                          >
                            クリエイター名
                          </span>
                        </div>
                        <div className="col-sm-7">
                          <div
                            className="tag-container-search"
                            onClick={(event) => {
                              event.stopPropagation()
                              inputTagRef.current.focus()
                              setIsShowPlaceHolder(false)
                            }}
                          >
                            {/* <span style={{position: "absolute", left: "20px", top: "8px", color: "#aaa", display: isShowPlaceHolder ? "block" : "none"}}>クリエイター名</span> */}
                            <SortableList
                              type="0"
                              axis={"xy"}
                              items={dataSearch.creator_name.filter(
                                (item) => item.name != "ai生成ツール使用素材",
                              )}
                              onSortEnd={onSortEnd}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center ac-mb-2 no-gutters">
                        <div className="col-sm-5 mb-1 mb-sm-0">
                          <span
                            className="float-left"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                          >
                            除外キーワード
                          </span>
                        </div>
                        <div className="col-sm-7">
                          <input
                            className="form-control ac-form-control"
                            style={{ padding: "4px 21px" }}
                            placeholder="除外キーワード"
                            onChange={(e) => {
                              setDataSearch({
                                ...dataSearch,
                                query_exclude: e.target.value,
                              })
                            }}
                            defaultValue={dataSearch.query_exclude}
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="row d-flex align-items-center ac-mb-2 no-gutters">
                        <div className="col-sm-5 mb-1 mb-sm-0"></div>
                        <div className="col-sm-7">
                          <div className="ac-d-flex ac-align-items-center ac-flex-wrap">
                            <div className="ac-mb-1 d-flex">
                              <Form.Check
                                type="checkbox"
                                // label="AI生成ツール使用素材を除く"
                                checked={dataSearch.exclude_ai}
                                onChange={(e) => {
                                  setDataSearch({
                                    ...dataSearch,
                                    exclude_ai: e.target.checked,
                                  })
                                }}
                              />
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-ai">
                                    チェックを外すとAI生成ツール使用素材を検索できます。
                                  </Tooltip>
                                }
                              >
                                <label
                                  className="form-check-label"
                                  htmlFor="ai-checkbox"
                                  style={{ cursor: "pointer" }}
                                >
                                  AI生成ツール使用素材を除く
                                </label>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row d-flex align-items-center ac-mb-2 no-gutters"
                        ref={categoryRef}
                      >
                        <div className="col-sm-5 mb-1 mb-sm-0">
                          <span className="float-left">カテゴリ</span>
                        </div>
                        <div className="col-sm-7">
                          <select
                            className="custom-select ac-custom-select"
                            multiple
                            style={{ display: "none" }}
                          >
                            {categories.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              )
                            })}
                          </select>
                          <span
                            className="ui-dropdownchecklist ui-dropdownchecklist-selector-wrapper ui-widget"
                            id="ddcl-c_names"
                            style={{
                              display: "inline-block",
                              cursor: "pointer",
                              overflow: "hidden",
                            }}
                            onClick={() => {
                              setIsOpenCategoryExclude(false)
                              setIsOpenCategory(!isOpenCategory)
                            }}
                          >
                            <span
                              className="ui-dropdownchecklist-selector ui-state-default ui-state-active"
                              tabIndex={0}
                              style={{
                                display: "inline-block",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                width: "250px",
                              }}
                            >
                              <span
                                className="ui-dropdownchecklist-text"
                                style={{
                                  display: "inline-block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                                title="カテゴリーを選択"
                              >
                                {dataSearch.category.length > 0
                                  ? dataSearch.category.join(",")
                                  : "カテゴリーを選択"}
                              </span>
                            </span>
                          </span>
                          {isOpenCategory && (
                            <div
                              className="ui-dropdownchecklist ui-dropdownchecklist-dropcontainer-wrapper ui-widget"
                              id="ddcl-c_names-ddw"
                              style={{
                                position: "absolute",
                                left: "0px",
                                top: "32px",
                                height: "200px",
                                width: "248px",
                                zIndex: 10000,
                              }}
                            >
                              <div
                                className="ui-dropdownchecklist-dropcontainer ui-widget-content"
                                style={{ overflowY: "auto", height: "200px" }}
                              >
                                {categories.map((item, index) => {
                                  return (
                                    <div
                                      className="ui-dropdownchecklist-item ui-state-default"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`ddcl-c_names-i${index}`}
                                        className="active"
                                        tabIndex={0}
                                        key={index}
                                        checked={dataSearch.category.includes(
                                          item.name,
                                        )}
                                        value={item.name}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            "category",
                                            e.target.value,
                                            e.target.checked,
                                          )
                                        }
                                      />
                                      <label
                                        className="ui-dropdownchecklist-text ml-2"
                                        htmlFor={`ddcl-c_names-i${index}`}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="row d-flex align-items-center ac-mb-2 no-gutters"
                        ref={categoryExcludeRef}
                      >
                        <div className="col-sm-5 mb-1 mb-sm-0">
                          <span className="float-left">除外カテゴリ</span>
                        </div>
                        <div className="col-sm-7">
                          <select
                            id="c_names"
                            className="custom-select ac-custom-select"
                            multiple
                            name="c_names[]"
                            style={{ display: "none" }}
                          >
                            {categories.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              )
                            })}
                          </select>
                          <span
                            className="ui-dropdownchecklist ui-dropdownchecklist-selector-wrapper ui-widget"
                            id="ddcl-c_names"
                            style={{
                              display: "inline-block",
                              cursor: "pointer",
                              overflow: "hidden",
                            }}
                            onClick={() => {
                              setIsOpenCategory(false)
                              setIsOpenCategoryExclude(!isOpenCategoryExclude)
                            }}
                          >
                            <span
                              className="ui-dropdownchecklist-selector ui-state-default ui-state-active"
                              tabIndex={0}
                              style={{
                                display: "inline-block",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                width: "250px",
                              }}
                            >
                              <span
                                className="ui-dropdownchecklist-text"
                                style={{
                                  display: "inline-block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                                title="カテゴリーを選択"
                              >
                                {dataSearch.category_exclude.length > 0
                                  ? dataSearch.category_exclude.join(",")
                                  : "除外カテゴリーを選択"}
                              </span>
                            </span>
                          </span>
                          {isOpenCategoryExclude && (
                            <div
                              className="ui-dropdownchecklist ui-dropdownchecklist-dropcontainer-wrapper ui-widget"
                              id="ddcl-c_names-ddw"
                              style={{
                                position: "absolute",
                                left: "0px",
                                top: "32px",
                                height: "200px",
                                width: "248px",
                                zIndex: 10000,
                              }}
                            >
                              <div
                                className="ui-dropdownchecklist-dropcontainer ui-widget-content"
                                style={{ overflowY: "auto", height: "200px" }}
                              >
                                {categories.map((item, index) => {
                                  return (
                                    <div
                                      className="ui-dropdownchecklist-item ui-state-default"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`ddcl-c_names-i${index}`}
                                        className="active"
                                        tabIndex={0}
                                        key={index}
                                        checked={dataSearch.category_exclude.includes(
                                          item.name,
                                        )}
                                        value={item.name}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            "category_exclude",
                                            e.target.value,
                                            e.target.checked,
                                          )
                                        }
                                      />
                                      <label
                                        className="ui-dropdownchecklist-text ml-2"
                                        htmlFor={`ddcl-c_names-i${index}`}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row d-flex align-items-center ac-mb-2 no-gutters">
                        <div className="col-sm-5 mb-1 mb-sm-0">
                          <span className="float-left">コンテナ形式</span>
                        </div>
                        <div className="col-sm-7">
                          <div className="ac-d-flex ac-align-items-center ac-flex-wrap">
                            <div className="custom-control custom-radio ac-custom-radio ac-mr-2 ac-mb-1">
                              <input
                                type="radio"
                                value=""
                                checked={dataSearch.mime_type == ""}
                                className="custom-control-input"
                                onChange={() => {
                                  setDataSearch({
                                    ...dataSearch,
                                    mime_type: "",
                                  })
                                }}
                              />
                              <label className="custom-control-label">
                                全て
                              </label>
                            </div>
                            <div className="custom-control custom-radio ac-custom-radio ac-mr-2 ac-mb-1">
                              <input
                                type="radio"
                                value="mov"
                                checked={dataSearch.mime_type == "mov"}
                                className="custom-control-input"
                                onChange={() => {
                                  setDataSearch({
                                    ...dataSearch,
                                    mime_type: "mov",
                                  })
                                }}
                              />
                              <label className="custom-control-label">
                                MOV
                              </label>
                            </div>
                            <div className="custom-control custom-radio ac-custom-radio ac-mr-2 ac-mb-1">
                              <input
                                type="radio"
                                value="mp4"
                                checked={dataSearch.mime_type == "mp4"}
                                onChange={() => {
                                  setDataSearch({
                                    ...dataSearch,
                                    mime_type: "mp4",
                                  })
                                }}
                                className="custom-control-input"
                              />
                              <label className="custom-control-label">
                                MP4
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center ac-mt-2">
                        <input
                          type="button"
                          value="検索"
                          onClick={() => {
                            handleSubmitSearch()
                          }}
                          className="ac-btn-xs ac-btn-photo ac-px-3 btn-search"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isOpenMoreSearch && (
                  <div className="advsearcharea-sm-screen d-none">
                    <div
                      className="mb-option-sidebar ac-px-2 ac-py-3 h-100 border-0 p-0 text-dark bg-white ac-z-index-99999"
                      onClick={() => {
                        if (
                          asPath != "/" &&
                          !queryExcludeSearchMobileRef.current.contains(event.target) &&
                          !categoryRefMobile.current.contains(event.target) &&
                          !categoryExcludeRefMobile.current.contains(event.target) &&
                          !buttonHideMobileSearchRef.current.contains(event.target) &&
                          !sortRef.current.contains(event.target) && !listItemSearchRef.current.contains(event.target)
                        ) {
                          const query = {
                            ...router.query,
                            search: dataSearch.search || undefined,
                            query_exclude: dataSearch.query_exclude || undefined,
                            category: dataSearch.category.length ? dataSearch.category.join(",") : undefined,
                            category_exclude: dataSearch.category_exclude.length ? dataSearch.category_exclude.join(",") : undefined,
                            mime_type: dataSearch.mime_type || undefined,
                            exclude_ai: dataSearch.exclude_ai,
                            page: 1,
                          }
                      
                          // Loại bỏ các tham số không có giá trị
                          const filteredQuery = Object.fromEntries(
                            Object.entries(query).filter(([_, value]) => value !== undefined)
                          );
                      
                          router.push(
                            {
                              pathname: routes.discovery.href,
                              query: filteredQuery,
                            },
                            `${routes.discovery.href}?${Object.entries(filteredQuery)
                              .map(([key, value]) => `${key}=${value}`)
                              .join("&")}`,
                          );
                        }
                      }}
                    >
                      <div className="mb-option-sidebar__area">
                        <button
                          className="btn btn-mobile-search-close position-absolute"
                          ref={buttonHideMobileSearchRef}
                          style={{ top: "0", right: "0" }}
                          onClick={() => setIsOpenMoreSearch(false)}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <div className="row d-flex align-items-center ac-mb-2 no-gutters text-777777">
                          検索フィルター
                        </div>
                        <div className="row d-flex align-items-center ac-mb-2 no-gutters">
                          <div className="col-sm-5 mb-1 mb-sm-0">
                            <span
                              className="float-left font-weight-bold"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                            >
                              クリエイター名
                            </span>
                          </div>
                          <div className="col-sm-7">
                            <div
                              className="tag-container-search"
                              onClick={(event) => {
                                event.stopPropagation()
                                inputTagRef1.current.focus()
                                setIsShowPlaceHolderMobile(false)
                              }}
                            >
                              <SortableList
                                type="1"
                                axis={"xy"}
                                items={dataSearch.creator_name.filter(
                                  (item) => item.name != "ai生成ツール使用素材",
                                )}
                                onSortEnd={onSortEnd}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="row d-flex align-items-center ac-mb-2 no-gutters"
                          ref={queryExcludeSearchMobileRef}
                        >
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            className="font-weight-bold"
                          >
                            除外キーワード
                          </span>
                          <div className="col-sm-8">
                            <input
                              className="form-control ac-form-control"
                              placeholder="除外キーワード"
                              onChange={(e) => {
                                setDataSearch({
                                  ...dataSearch,
                                  query_exclude: e.target.value,
                                })
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault()
                                  setIsOpenMoreSearch(false)
                                  router.push({
                                    pathname: routes.discovery.href,
                                    query: {
                                      ...router.query,
                                      page: 1,
                                      query_exclude: e.target.value,
                                    },
                                  })
                                }
                              }}
                              defaultValue={dataSearch.query_exclude}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row d-flex align-items-center ac-mb-2 no-gutters">
                          <div className="col-sm-5 mb-1 mb-sm-0"></div>
                          <div className="col-sm-7">
                            <div className="ac-d-flex ac-align-items-center ac-flex-wrap">
                              <div className="ac-mb-1 d-flex">
                                <Form.Check
                                  type="checkbox"
                                  // label="AI生成ツール使用素材を除く"
                                  checked={dataSearch.exclude_ai}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked
                                    setDataSearch({
                                      ...dataSearch,
                                      exclude_ai: isChecked,
                                    })
                                    const newQuery = {
                                      ...router.query,
                                      page: 1,
                                      ...(isChecked && { exclude_ai: true }),
                                    }
                                    if (!isChecked) {
                                      delete newQuery.exclude_ai
                                    }
                                    router.push({
                                      pathname: routes.discovery.href,
                                      query: newQuery,
                                    })
                                    setIsOpenMoreSearch(false)
                                  }}
                                />
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id="tooltip-ai">
                                      チェックを外すとAI生成ツール使用素材を検索できます。
                                    </Tooltip>
                                  }
                                >
                                  <label
                                    className="form-check-label"
                                    htmlFor="ai-checkbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    AI生成ツール使用素材を除く
                                  </label>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row d-flex align-items-center ac-mb-2 no-gutters"
                          ref={categoryRefMobile}
                        >
                          <span className="mb-2 font-weight-bold">
                            カテゴリ
                          </span>
                          <div className="col-sm-7">
                            <select
                              className="custom-select ac-custom-select"
                              multiple
                              style={{ display: "none" }}
                            >
                              {categories.map((item) => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                )
                              })}
                            </select>
                            <span
                              className="ui-dropdownchecklist ui-dropdownchecklist-selector-wrapper ui-widget"
                              id="ddcl-c_names"
                              style={{
                                display: "inline-block",
                                cursor: "pointer",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                setIsOpenCategoryExcludeMobile(false)
                                setIsOpenCategoryMobile(!isOpenCategoryMobile)
                              }}
                            >
                              <span
                                className="ui-dropdownchecklist-selector ui-state-default ui-state-active"
                                tabIndex={0}
                                style={{
                                  display: "inline-block",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  width: "250px",
                                }}
                                onClick={(e) => {
                                  if (isOpenCategoryMobile && asPath != "/") {
                                    const isDataChanged =
                                      JSON.stringify(
                                        prevDataSearchRef.current,
                                      ) !== JSON.stringify(dataSearch)

                                    if (!isDataChanged) {
                                      return
                                    }
                                    const hasCategory =
                                      dataSearch?.category &&
                                      dataSearch.category.length > 0

                                    const hasCreatorName =
                                      dataSearch?.creator_name &&
                                      dataSearch.creator_name.length > 0

                                    const query = {
                                      ...router.query,
                                      page: 1,
                                      ...(hasCategory && {
                                        category: dataSearch.category.join(","),
                                      }),
                                      ...(hasCreatorName && {
                                        creator_name: dataSearch.creator_name.join(","),
                                      }),
                                    }
                                    if (!hasCategory) {
                                      delete query.category
                                    }
                                    if (!hasCreatorName) {
                                      delete query.creator_name
                                    }
                                    router.push(
                                      {
                                        pathname: routes.discovery.href,
                                        query,
                                      },
                                      `${
                                        routes.discovery.href
                                      }?${Object.entries(query)
                                        .map(
                                          ([key, value]) => `${key}=${value}`,
                                        )
                                        .join("&")}`,
                                    )
                                    prevDataSearchRef.current = dataSearch
                                    setIsOpenMoreSearch(false)
                                  }
                                }}
                              >
                                <span
                                  className="ui-dropdownchecklist-text"
                                  style={{
                                    display: "inline-block",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "246px",
                                  }}
                                  title="カテゴリーを選択"
                                >
                                  {dataSearch.category.length > 0
                                    ? dataSearch.category.join(",")
                                    : "カテゴリーを選択"}
                                </span>
                              </span>
                            </span>
                            {isOpenCategoryMobile && (
                              <div
                                className="ui-dropdownchecklist ui-dropdownchecklist-dropcontainer-wrapper ui-widget"
                                id="ddcl-c_names-ddw"
                                style={{
                                  position: "absolute",
                                  left: "0px",
                                  top: "36px",
                                  height: "204px",
                                  width: "248px",
                                  zIndex: 10000,
                                }}
                              >
                                <div
                                  className="ui-dropdownchecklist-dropcontainer ui-widget-content"
                                  style={{ overflowY: "auto", height: "200px" }}
                                >
                                  {categories.map((item, index) => {
                                    return (
                                      <div
                                        className="ui-dropdownchecklist-item ui-state-default"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <input
                                          type="checkbox"
                                          id={`ddcl-c_names-i${index}`}
                                          className="active"
                                          tabIndex={0}
                                          key={index}
                                          checked={dataSearch.category.includes(
                                            item.name,
                                          )}
                                          value={item.name}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              "category",
                                              e.target.value,
                                              e.target.checked,
                                            )
                                          }
                                        />
                                        <label
                                          className="ui-dropdownchecklist-text ml-2"
                                          htmlFor={`ddcl-c_names-i${index}`}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item.name}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="row d-flex align-items-center ac-mb-2 no-gutters"
                          ref={categoryExcludeRefMobile}
                        >
                          <span className="mb-2 font-weight-bold">
                            除外カテゴリ
                          </span>
                          <div className="col-sm-7">
                            <select
                              id="c_names"
                              className="custom-select ac-custom-select"
                              multiple
                              name="c_names[]"
                              style={{ display: "none" }}
                            >
                              {categories.map((item) => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                )
                              })}
                            </select>
                            <span
                              className="ui-dropdownchecklist ui-dropdownchecklist-selector-wrapper ui-widget"
                              id="ddcl-c_names"
                              style={{
                                display: "inline-block",
                                cursor: "pointer",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                setIsOpenCategoryMobile(false)
                                setIsOpenCategoryExcludeMobile(
                                  !isOpenCategoryExcludeMobile,
                                )
                              }}
                            >
                              <span
                                className="ui-dropdownchecklist-selector ui-state-default ui-state-active"
                                tabIndex={0}
                                style={{
                                  display: "inline-block",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  width: "250px",
                                }}
                                onClick={(e) => {
                                  if (isOpenCategoryExcludeMobile && asPath != "/") {
                                    const isDataChanged =
                                      JSON.stringify(
                                        prevDataSearchRef.current,
                                      ) !== JSON.stringify(dataSearch)

                                    if (!isDataChanged) {
                                      return
                                    }

                                    const hasCategoryExclude =
                                      dataSearch?.category_exclude &&
                                      dataSearch.category_exclude.length > 0
                                    
                                    const hasCreatorName =
                                      dataSearch?.creator_name &&
                                      dataSearch.creator_name.length > 0

                                    const query = {
                                      ...router.query,
                                      page: 1,
                                      ...(hasCategoryExclude && {
                                        category_exclude: dataSearch.category_exclude.join(
                                          ",",
                                        ),
                                      }),
                                      ...(hasCreatorName && {
                                        creator_name: dataSearch.creator_name.join(","),
                                      }),
                                    }
                                    if (!hasCategoryExclude) {
                                      delete query.category_exclude
                                    }
                                    if (!hasCreatorName) {
                                      delete query.creator_name
                                    }
                                    router.push(
                                      {
                                        pathname: routes.discovery.href,
                                        query,
                                      },
                                      `${
                                        routes.discovery.href
                                      }?${Object.entries(query)
                                        .map(
                                          ([key, value]) => `${key}=${value}`,
                                        )
                                        .join("&")}`,
                                    )
                                    prevDataSearchRef.current = dataSearch
                                    setIsOpenMoreSearch(false)
                                  }
                                }}
                              >
                                <span
                                  className="ui-dropdownchecklist-text"
                                  style={{
                                    display: "inline-block",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "246px",
                                  }}
                                  title="カテゴリーを選択"
                                >
                                  {dataSearch.category_exclude.length > 0
                                    ? dataSearch.category_exclude.join(",")
                                    : "除外カテゴリーを選択"}
                                </span>
                              </span>
                            </span>
                            {isOpenCategoryExcludeMobile && (
                              <div
                                className="ui-dropdownchecklist ui-dropdownchecklist-dropcontainer-wrapper ui-widget"
                                id="ddcl-c_names-ddw"
                                style={{
                                  position: "absolute",
                                  left: "0px",
                                  top: "36px",
                                  height: "204px",
                                  width: "248px",
                                  zIndex: 10000,
                                }}
                              >
                                <div
                                  className="ui-dropdownchecklist-dropcontainer ui-widget-content"
                                  style={{ overflowY: "auto", height: "200px" }}
                                >
                                  {categories.map((item, index) => {
                                    return (
                                      <div
                                        className="ui-dropdownchecklist-item ui-state-default"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <input
                                          type="checkbox"
                                          id={`ddcl-c_names-i${index}`}
                                          className="active"
                                          tabIndex={0}
                                          key={index}
                                          checked={dataSearch.category_exclude.includes(
                                            item.name,
                                          )}
                                          value={item.name}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              "category_exclude",
                                              e.target.value,
                                              e.target.checked,
                                            )
                                          }
                                        />
                                        <label
                                          className="ui-dropdownchecklist-text ml-2"
                                          htmlFor={`ddcl-c_names-i${index}`}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item.name}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row d-flex align-items-center ac-mb-2 no-gutters">
                          <span className="mb-2">コンテナ形式</span>
                          <div className="col-sm-7">
                            <div className="ac-d-flex ac-align-items-center ac-flex-wrap">
                              <div className="custom-control custom-radio ac-custom-radio ac-mr-2 ac-mb-1">
                                <input
                                  type="radio"
                                  value=""
                                  checked={dataSearch.mime_type == ""}
                                  className="custom-control-input"
                                  onChange={(e) => {
                                    setDataSearch({
                                      ...dataSearch,
                                      mime_type: "",
                                    })
                                    if (asPath != "/") {
                                      const {
                                        mime_type,
                                        ...updatedQuery
                                      } = router.query
                                      router.push({
                                        pathname: routes.discovery.href,
                                        query: { ...updatedQuery, page: 1 }, // Gửi query mới không có mime_type
                                      })
                                      setIsOpenMoreSearch(false)
                                    }
                                  }}
                                />
                                <label className="custom-control-label">
                                  全て
                                </label>
                              </div>
                              <div className="custom-control custom-radio ac-custom-radio ac-mr-2 ac-mb-1">
                                <input
                                  type="radio"
                                  value="mov"
                                  checked={dataSearch.mime_type == "mov"}
                                  className="custom-control-input"
                                  onChange={() => {
                                    setDataSearch({
                                      ...dataSearch,
                                      mime_type: "mov",
                                    })
                                    if (asPath != "/") {
                                      router.push({
                                        pathname: routes.discovery.href,
                                        query: {
                                          ...router.query,
                                          page: 1,
                                          mime_type: "mov",
                                        },
                                      })
                                      setIsOpenMoreSearch(false)
                                    }
                                  }}
                                />
                                <label className="custom-control-label">
                                  MOV
                                </label>
                              </div>
                              <div className="custom-control custom-radio ac-custom-radio ac-mr-2 ac-mb-1">
                                <input
                                  type="radio"
                                  value="mp4"
                                  checked={dataSearch.mime_type == "mp4"}
                                  onChange={() => {
                                    setDataSearch({
                                      ...dataSearch,
                                      mime_type: "mp4",
                                    })
                                    if (asPath != "/") {
                                      router.push({
                                        pathname: routes.discovery.href,
                                        query: {
                                          ...router.query,
                                          page: 1,
                                          mime_type: "mp4",
                                        },
                                      })
                                      setIsOpenMoreSearch(false)
                                    }
                                  }}
                                  className="custom-control-input"
                                />
                                <label className="custom-control-label">
                                  MP4
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {asPath != "/" && (
                          <div>
                            <hr />
                            <div ref={sortRef}>
                              <span className="font-weight-bold">並べ替え</span>
                              <div className="custom-control custom-radio ac-custom-radio custom-radio-sm ac-mb-1 mt-1">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  value="-download_count"
                                  checked={
                                    sort_by === "-download_count" || !sort_by
                                  }
                                  onChange={(e) => {
                                    router.push({
                                      pathname: routes.discovery.href,
                                      query: {
                                        ...router.query,
                                        page: 1,
                                        sort_by: e.target.value,
                                      },
                                    })
                                    setIsOpenMoreSearch(false)
                                  }}
                                />
                                <label className="custom-control-label">
                                  関連性の高い順
                                </label>
                              </div>
                              <div className="custom-control custom-radio ac-custom-radio custom-radio-sm ac-mb-1">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  value="-id"
                                  checked={sort_by === "-id"}
                                  onChange={(e) => {
                                    router.push({
                                      pathname: routes.discovery.href,
                                      query: {
                                        ...router.query,
                                        page: 1,
                                        sort_by: e.target.value,
                                      },
                                    })
                                    setIsOpenMoreSearch(false)
                                  }}
                                />
                                <label className="custom-control-label">
                                  新着順
                                </label>
                              </div>
                              <hr />
                              <span className="mb-1 font-weight-bold">
                                表示件数
                              </span>
                              {sizes.map((item, index) => (
                                <div
                                  key={index}
                                  className="custom-control custom-radio ac-custom-radio custom-radio-sm ac-mb-1"
                                >
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={item}
                                    checked={page_size ? item == page_size : item == 24}
                                    onChange={(e) => {
                                      router.push({
                                        pathname: routes.discovery.href,
                                        query: {
                                          ...router.query,
                                          page: 1,
                                          page_size: item,
                                        },
                                      });
                                      setIsOpenMoreSearch(false)
                                    }}
                                  />
                                  <label className="custom-control-label">
                                    {t("page_size_postfix", {
                                      page_size: item,
                                    })}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {asPath == "/" && (
                          <div className="text-center ac-mt-2">
                            <input
                              type="button"
                              value="検索"
                              onClick={() => {
                                handleSubmitSearch()
                              }}
                              className="ac-btn-xs ac-btn-photo ac-px-3 btn-search"
                            />
                          </div>
                        )}
                        <div className="mt-3 mt-sm-0" ref={listItemSearchRef}>
                          <div className="d-flex align-items-center flex-wrap flex-grow-1">
                            {creator_name?.split(",").map((item) => {
                              return (
                                <button
                                  type="button"
                                  className="btn btn-light border rounded ac-px-1 py-1 ac-mr-1 ac-mb-1 d-inline-flex align-items-center"
                                >
                                  {item}{" "}
                                  <span className="ml-2 delete-tag-search">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      aria-hidden="true"
                                      role="img"
                                      width="14"
                                      height="14"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                      onClick={() => {
                                        const creatorString = creator_name
                                          .split(",")
                                          .filter(
                                            (creator_name) =>
                                              creator_name !== item,
                                          )

                                        const query = {
                                          ...router.query,
                                          page: 1,
                                          ...(creatorString.length > 0 && {
                                            creator_name: creatorString.join(
                                              ",",
                                            ),
                                          }),
                                        }

                                        if (creatorString.length === 0) {
                                          delete query.creator_name
                                        }
                                        router.push(
                                          {
                                            pathname: routes.discovery.href,
                                            query,
                                          },
                                          `${
                                            routes.discovery.href
                                          }?${Object.entries(query)
                                            .map(
                                              ([key, value]) =>
                                                `${key}=${value}`,
                                            )
                                            .join("&")}`,
                                        )
                                      }}
                                    >
                                      <path
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                        d="M20 20L4 4m16 0L4 20"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              )
                            })}
                            {category?.split(",").map((item) => {
                              return (
                                <button
                                  type="button"
                                  className="btn btn-light border rounded ac-px-1 py-1 ac-mr-1 ac-mb-1 d-inline-flex align-items-center"
                                >
                                  {item}{" "}
                                  <span
                                    className="ml-2 delete-tag-search"
                                    onClick={() => {
                                      const updatedCategory = category
                                        .split(",")
                                        .filter((catItem) => catItem !== item)
                                        .join(",")

                                      const query = {
                                        ...router.query,
                                        page: 1,
                                        ...(updatedCategory && {
                                          category: updatedCategory,
                                        }),
                                      }

                                      if (!updatedCategory) {
                                        delete query.category // Xóa category nếu rỗng
                                      }
                                      setIsOpenMoreSearch(false)
                                      router.push(
                                        {
                                          pathname: routes.discovery.href,
                                          query,
                                        },
                                        `${
                                          routes.discovery.href
                                        }?${Object.entries(query)
                                          .map(
                                            ([key, value]) => `${key}=${value}`,
                                          )
                                          .join("&")}`,
                                      )
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      aria-hidden="true"
                                      role="img"
                                      width="14"
                                      height="14"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                        d="M20 20L4 4m16 0L4 20"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              )
                            })}
                            {category_exclude?.split(",").map((item) => {
                              return (
                                <button
                                  type="button"
                                  className="btn btn-light alert-danger rounded ac-px-1 py-1 ac-mr-1 ac-mb-1 d-inline-flex align-items-center"
                                >
                                  {item}{" "}
                                  <span
                                    className="ml-2"
                                    onClick={() => {
                                      const updatedCategory = category_exclude
                                        .split(",")
                                        .filter((catItem) => catItem !== item)
                                        .join(",")

                                      const query = {
                                        ...router.query,
                                        page: 1,
                                        ...(updatedCategory && {
                                          category_exclude: updatedCategory,
                                        }),
                                      }

                                      if (!updatedCategory) {
                                        delete query.category_exclude // Xóa category nếu rỗng
                                      }
                                      setIsOpenMoreSearch(false)
                                      router.push(
                                        {
                                          pathname: routes.discovery.href,
                                          query,
                                        },
                                        `${
                                          routes.discovery.href
                                        }?${Object.entries(query)
                                          .map(
                                            ([key, value]) => `${key}=${value}`,
                                          )
                                          .join("&")}`,
                                      )
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      aria-hidden="true"
                                      role="img"
                                      width="14"
                                      height="14"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                        d="M20 20L4 4m16 0L4 20"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              )
                            })}
                            {dataSearch.query_exclude && query_exclude && (
                              <button
                                type="button"
                                className="btn btn-light border rounded ac-px-1 py-1 ac-mr-1 ac-mb-1 d-inline-flex align-items-center"
                              >
                                {query_exclude}{" "}
                                <span
                                  className="ml-2 delete-tag-search"
                                  onClick={() => {
                                    const query = {
                                      ...router.query,
                                      page: 1,
                                    }

                                    // Xóa query_exclude khỏi query
                                    delete query.query_exclude
                                    setDataSearch({
                                      ...dataSearch,
                                      query_exclude: "",
                                    })
                                    setIsOpenMoreSearch(false)
                                    router.push(
                                      {
                                        pathname: routes.discovery.href,
                                        query,
                                      },
                                      `${
                                        routes.discovery.href
                                      }?${Object.entries(query)
                                        .map(
                                          ([key, value]) => `${key}=${value}`,
                                        )
                                        .join("&")}`,
                                    )
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="14"
                                    height="14"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                      d="M20 20L4 4m16 0L4 20"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            )}
                            {dataSearch?.mime_type && mime_type && (
                              <button
                                type="button"
                                className="btn btn-light border rounded ac-px-1 py-1 ac-mr-1 ac-mb-1 d-inline-flex align-items-center"
                              >
                                {dataSearch?.mime_type}{" "}
                                <span
                                  className="ml-2 delete-tag-search"
                                  onClick={() => {
                                    const query = {
                                      ...router.query,
                                      page: 1,
                                    }

                                    delete query.mime_type
                                    setDataSearch({
                                      ...dataSearch,
                                      mime_type: "",
                                    })
                                    setIsOpenMoreSearch(false)
                                    router.push(
                                      {
                                        pathname: routes.discovery.href,
                                        query,
                                      },
                                      `${
                                        routes.discovery.href
                                      }?${Object.entries(query)
                                        .map(
                                          ([key, value]) => `${key}=${value}`,
                                        )
                                        .join("&")}`,
                                    )
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="14"
                                    height="14"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-width="2"
                                      d="M20 20L4 4m16 0L4 20"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            )}
                            {sort_by == "-id" && (
                              <button
                                type="button"
                                className="btn btn-light border rounded ac-px-1 py-1 ac-mr-1 ac-mb-1 d-inline-flex align-items-center"
                              >
                                新着順{" "}
                                <span
                                  className="ml-2 delete-tag-search"
                                  onClick={() => {
                                    const query = {
                                      ...router.query,
                                      page: 1,
                                    }

                                    delete query.sort_by
                                    setIsOpenMoreSearch(false)
                                    router.push(
                                      {
                                        pathname: routes.discovery.href,
                                        query,
                                      },
                                      `${
                                        routes.discovery.href
                                      }?${Object.entries(query)
                                        .map(
                                          ([key, value]) => `${key}=${value}`,
                                        )
                                        .join("&")}`,
                                    )
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="14"
                                    height="14"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-width="2"
                                      d="M20 20L4 4m16 0L4 20"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            )}
                            {page_size && page_size != 24 && (
                              <button
                                type="button"
                                className="btn btn-light border rounded ac-px-1 py-1 ac-mr-1 ac-mb-1 d-inline-flex align-items-center"
                              >
                                {page_size}件ずつ表示{" "}
                                <span
                                  className="ml-2 delete-tag-search"
                                  onClick={() => {
                                    const query = {
                                      ...router.query,
                                      page: 1,
                                    }

                                    delete query.page_size
                                    setIsOpenMoreSearch(false)
                                    router.push(
                                      {
                                        pathname: routes.discovery.href,
                                        query,
                                      },
                                      `${
                                        routes.discovery.href
                                      }?${Object.entries(query)
                                        .map(
                                          ([key, value]) => `${key}=${value}`,
                                        )
                                        .join("&")}`,
                                    )
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="14"
                                    height="14"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-width="2"
                                      d="M20 20L4 4m16 0L4 20"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            )}
                            {(category || mime_type || category_exclude || creator_name || (page_size && page_size != 24) || (sort_by && sort_by != "-download_count")) && (
                              <span
                                className="ac-text-blue d-inline-flex ac-mr-1 ac-mb-1 cursor-pointer"
                                onClick={() => {
                                  const query = {
                                    exclude_ai: true,
                                    page: 1,
                                  }
                                  if (keySearch) {
                                    query.search = keySearch
                                  }
                                  setDataSearch({
                                    query_exclude: "",
                                    category: [],
                                    category_exclude: [],
                                    mime_type: "",
                                    exclude_ai: true,
                                    creator_name: [],
                                  })
                                  setIsOpenMoreSearch(false)
                                  router.push(
                                    {
                                      pathname: routes.discovery.href,
                                      query,
                                    },
                                    `${routes.discovery.href}?${Object.entries(
                                      query,
                                    )
                                      .map(([key, value]) => `${key}=${value}`)
                                      .join("&")}`,
                                  )
                                }}
                              >
                                すべてクリア
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="omega-overlay"
                      onClick={() => {
                        setIsOpenMoreSearch(false)
                      }}
                    ></div>
                  </div>
                )}
              </div>
              {onClear && value && (
                <div className={"clearContainer"}>
                  <button
                    onClick={onClear}
                    title={clearLabel}
                    className={`${"clearButton"} ${small ? "small" : ""}`}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
              )}
            </div>
          </div>
          {isExpanded && (
            <div
              id={resultContainerId}
              className={classNames(
                "resultContainer",
                "searchContainerExpandedShadow",
                {
                  "rounded-bottom": rounded,
                },
              )}
            >
              {renderListChoices}
              {children}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
const mapStateToProps = createStructuredSelector({
  isAuthen: makeSelectIsAuthenticated(),
})
export default connect(mapStateToProps, null)(withI18n(SearchPanel))
